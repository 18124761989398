import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import { CardWrapper, IconWrapper, ContentWrapper } from './styles'
import { Subtitle } from '../typography'
import { colors } from '../../utils'
import ArrowIcon from '../../static/arrow.svg'

import categoriesIcons from './utils/categories-icons'

const CategoryCard = ({ name, path }) => {
  const [isClicked, setIsClicked] = useState(false)
  const { formatMessage, locale } = useIntl()
 
  useEffect(() => {
    const urlParts =
      typeof window !== 'undefined' && window.location.pathname.split('/')
    const result =
      typeof window !== 'undefined'
        ? urlParts[urlParts.length - 2] === path
        : false
    setIsClicked(result)
  }, [])
  const handleClick = () => {
    let urlParts = window.location.pathname.split('/')
    urlParts[urlParts.length - 2] === path ? navigate('/'):navigate(`/categories/${path}/`)
  }

  return (
    <CardWrapper
      tabindex="0"
      role="button"
      clicked={isClicked}
      onClick={handleClick}
      dir={locale === 'en' ? 'ltr' : 'rtl'}
    >
      <ContentWrapper>
        <IconWrapper dir={locale === 'en' ? 'ltr' : 'rtl'}>
          {categoriesIcons[name]}
        </IconWrapper>
        <Subtitle
          color={isClicked ? colors.white : colors.primary}
          style={{ textTransform: 'capitalize' }}
        >
          {formatMessage({ id: name })}
        </Subtitle>
      </ContentWrapper>
      <ArrowIcon className="arrow" />
    </CardWrapper>
  )
}

CategoryCard.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default CategoryCard
