import styled from 'styled-components'

import { colors } from '../../utils'

export const CardWrapper = styled.div`
  background: ${({ clicked }) => (clicked ? colors.primary : colors.white)};
  border: 0.5px solid #b6b6b6;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(36, 36, 36, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in;

  .arrow {
    transition: all 0.3s ease-in;
    transform: ${({ dir }) => (dir === 'rtl' ? 'rotate(-180deg)' : '')};

    path {
      fill: ${({ clicked }) => (clicked ? colors.white : colors.primary)};
    }
  }

  :hover {
    transform: translateY(-2px);

    .arrow {
      transform: ${({ dir }) =>
        dir === 'rtl'
          ? 'rotate(-180deg) translateX(10px)'
          : 'translateX(10px)'};
    }
  }

  :active {
    transform: translateY(0);
  }

  svg {
    width: 3.5rem;
    height: 3.5rem;
    display: block;
  }

  path {
    fill: ${colors.white};
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  margin: ${({ dir }) => (dir === 'rtl' ? '0 0 0 1.2rem' : '0 1.2rem 0 0')};
`
