/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

const SvgComponent = props => (
  <svg width={31} height={31} viewBox="0 0 31 31" fill="none" {...props}>
    <path
      d="M3.808 16.344l17.712-.063-4.458 4.503 1.768 1.756 7.474-7.527-7.527-7.473-1.757 1.77 4.492 4.47-17.713.064.01 2.5z"
      fill="#F2F2F2"
    />
  </svg>
)

export default SvgComponent
