import styled from 'styled-components'
import { colors } from '../../utils'

export const Container = styled.div`
  background-color: ${colors.lightGray};
  margin: 2rem auto;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
    transform: scale(1.05);
  }
`
export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`
export const ProductImg = styled.img`
  margin: 0 auto;
  object-fit: cover;
  width: 25rem;
  height: 25rem;
  @media (min-width: 800px) {
    width: 24rem;
    height: 24rem;
  }
`
export const ColumnDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const Details = styled.div`
  width: 25rem;
  align-items: flex-start;
  background-color: white;
  padding: 1rem;
  display: flex !important;
  justify-content: space-around;
  @media (min-width: 800px) {
    width: 24rem;
  }
`
