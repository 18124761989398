import React from 'react'

import Regulators from '../../../static/regulators.svg'
import Valves from '../../../static/valves.svg'
import Ovens from '../../../static/ovens.svg'
import Fittings from '../../../static/fittings.svg'
import Cookers from '../../../static/cookers.svg'
import Hoses from '../../../static/hoses.svg'
import Tools from '../../../static/tools.svg'
import HomeApplicances from '../../../static/homeApplicances.svg'

export default {
  regulators: <Regulators />,
  valves: <Valves />,
  ovens: <Ovens />,
  fittings: <Fittings />,
  cookers: <Cookers />,
  hoses: <Hoses />,
  tools: <Tools />,
  'home applicances': <HomeApplicances />,
}
