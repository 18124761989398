import React from 'react'
import PropTypes from 'prop-types'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import { colors } from '../../utils'
import { CardTitle as Title, Subtitle } from '../typography'
import { Container, ProductImg, Details, ColumnDetails } from './card.style'

const Card = ({ price, imageUrlCompressed, category, model, name }) => {
  Card.propTypes = {
    price: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    imageUrlCompressed: PropTypes.number.isRequired,
  }

  return (
    <Container>
      <Link
        style={{ color: 'inherit', textDecoration: 'none' }}
        to={`/products/${model}`}
      >
        <ProductImg alt={name} src={imageUrlCompressed} />
        <Details>
          <ColumnDetails style={{ width: `90%` }}>
            <Title style={{ color: `${colors.primary}` }}>{name}</Title>
            <Subtitle>
              <FormattedMessage id={category} />
            </Subtitle>
          </ColumnDetails>
          <ColumnDetails>
            <Subtitle>{`${price}$`}</Subtitle>
            <Subtitle style={{ color: `${colors.lightGray}` }}>10</Subtitle>
          </ColumnDetails>
        </Details>
      </Link>
    </Container>
  )
}
export default Card
