/* eslint-disable prettier/prettier */
import React from "react";

const SvgComponent = () => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1195.15 1195.15"
    xmlSpace="preserve"
  >
    <style>
      {
        ".st0{fill:#fff}.st1{fill:#c1272d}.st13,.st16,.st17,.st19{fill:none;stroke:#c1272d;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.st16,.st17,.st19{fill:#fff}.st17,.st19{fill:none;stroke:#fff;stroke-width:10}.st19{fill:#fff;stroke:#c1272d;stroke-width:7;stroke-linecap:square}"
      }
    </style>
    <circle
      cx={597.58}
      cy={597.58}
      r={597.08}
      fill="#c1272d"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      d="M210.69 505.34c3.91-2.41 212.88 38 212.88 38s-80.69 156-77.59 203c3.1 47 34.14 105 34.14 105l-233.43-37s-129-190 64-309z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={7}
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeMiterlimit={10}
    />
    <path
      className="st0"
      d="M517.19 502.34c-.17 0-.34-.01-.5-.04L404.66 486c-4.87-.65-48.28-6.91-59-23.59-2.51-3.91-3.14-8.26-1.83-12.59 6.76-22.22 4.87-170.44 4.85-171.93 0-.21.01-.42.05-.62.21-1.28 5.45-31.59 23.43-44.28 17.56-12.39 78.11-18.38 80.68-18.62.06-.01.13-.01.19-.01 8.4-.34 16.96-.52 25.45-.52 105.13 0 183.97 26.67 189.56 28.61 43 7.1 405.97 67.01 409.98 68.01 4.84 1.21 9.77 9.81 12.28 14.83.28.56.41 1.19.36 1.81-.01.14-.99 14.14-.99 34.75 0 21.22-11.48 25.57-14.32 26.32-4.91 2.37-60.75 29.38-81.62 39.81-22.41 11.2-64.62 13.28-66.4 13.37h-.19l-250.07-1.98c-5.71 29.69-13.14 36.03-18.45 36.03-.56 0-1-.08-1.32-.16L626.59 468l-2.05 6.84a3.507 3.507 0 01-2.53 2.4l-104 25c-.27.06-.55.1-.82.1z"
    />
    <path
      className="st1"
      d="M478.49 217.32c109.19 0 188.69 28.52 188.69 28.52s406 67 410 68 10 13 10 13-1 14-1 35-12 23-12 23-60 29-82 40-65 13-65 13l-253-2c-6.07 33.8-13.64 36.05-15.55 36.05-.29 0-.45-.05-.45-.05l-34-8-3 10-104 25-112.02-16.3s-64.98-8.7-57.98-31.7 5-173 5-173 5-30 22-42 79-18 79-18c8.6-.36 17.04-.52 25.31-.52m0-7c-8.54 0-17.15.18-25.59.52-.13.01-.26.01-.39.03-6.53.63-64.37 6.55-82.36 19.25-19.17 13.53-24.64 45.23-24.87 46.57-.07.41-.1.83-.09 1.24.55 41.42.78 152.86-4.7 170.87-1.63 5.34-.86 10.7 2.23 15.5 5.25 8.17 17.06 14.68 36.13 19.9 12.92 3.54 24.44 5.14 25.35 5.26l111.98 16.29c.33.05.67.07 1.01.07.55 0 1.1-.06 1.64-.19l104-25a7.014 7.014 0 005.07-4.79l1.11-3.69 27.5 6.47c.55.14 1.28.26 2.15.26 9.33 0 16.32-11.79 21.32-36.01l247.17 1.95h.06c.11 0 .22 0 .33-.01 1.82-.08 44.75-2.2 67.81-13.73 20.59-10.29 75.26-36.74 81.36-39.69 4.18-1.28 16.51-6.98 16.51-29.57 0-20.47.97-34.36.98-34.5.09-1.25-.16-2.51-.72-3.63-.07-.14-1.69-3.37-3.87-6.77-3.85-6.02-7.05-8.98-10.7-9.89-4.06-1.01-340.73-56.59-409.95-68.02-3.68-1.28-22.94-7.76-53.69-14.3-30.94-6.55-79.76-14.39-136.78-14.39z"
    />
    <path
      className="st13"
      d="M356.19 533.84s-164 102-61 302M338.19 530.84s-164 102-61 302M320.19 527.84s-164 103-61 303M301.19 525.84s-165 100-62 300M281.19 523.84s-162 99-59 299M264.19 520.84s-163 100-60 300M246.19 518.84s-162 98-59 298M228.19 515.84s-161 97-58 297M211.19 513.84s-160 96-57 296"
    />
    <path
      className="st16"
      d="M402.19 502.84l47 7s-182 238-41 389l-60-7s-144-154 54-389z"
    />
    <path
      className="st16"
      d="M456.19 510.84l108 22s-135 226-38 381l-118-15s-150-153 48-388z"
    />
    <path
      className="st0"
      d="M714.19 960.34c-.17 0-.34-.01-.51-.04-.35-.05-.69-.11-1.04-.17l-175.01-27.84a3.522 3.522 0 01-2.48-1.7c-81.02-139.38-23.59-278.51 10.72-361.63 5.56-13.48 10.37-25.12 14.01-35.3.14-.39.97-2.35 3.52-2.35 4.52 0 65.3 7.79 101.12 12.43 22.66 2.94 46.55 6.07 65.55 8.59 8.89 1.18 16.28 2.17 21.45 2.88 2.59.35 4.63.64 6.02.83 2.76.39 4.58.65 5.07 3.12a3.52 3.52 0 01-2.12 3.92c-137.14 55.85-138.79 183.16-133.14 235.3 4.16 38.36 15.82 75.83 32.84 105.5 16.21 28.27 35.74 46.26 53.58 49.34l.95.15c1.9.3 3.2 2.08 2.91 3.98a3.482 3.482 0 01-3.44 2.99z"
    />
    <path
      className="st1"
      d="M563.41 534.81c7.5 0 196.32 24.8 195.77 25.03C539.04 649.49 631 942.52 713.21 956.69l-175.03-27.85c-93-160-1.56-319.75 25-394 .01-.02.09-.03.23-.03m149.81 421.87l.97.15c-.33-.04-.65-.09-.97-.15M563.41 527.81c-3.81 0-6.05 2.52-6.82 4.68-3.61 10.1-8.4 21.7-13.95 35.14-34.56 83.72-92.39 223.85-10.51 364.73a7.016 7.016 0 004.95 3.4l174.99 27.84c.37.06.73.12 1.1.17.34.05.68.07 1.02.07a7 7 0 006.91-5.95c.58-3.8-2.02-7.36-5.82-7.97l-.92-.15c-16.8-2.92-35.43-20.28-51.12-47.63-16.78-29.27-28.29-66.25-32.4-104.14-5.57-51.35-3.96-176.73 130.98-231.68a7.002 7.002 0 004.23-7.83c-.35-1.8-1.65-4.94-6.34-5.67-.38-.06-.94-.14-1.67-.25-1.39-.2-3.44-.48-6.04-.84-5.1-.7-12.53-1.69-21.46-2.88-19-2.52-42.9-5.66-65.56-8.59-63.8-8.26-97.97-12.45-101.57-12.45z"
    />
    <path className="st17" d="M914.19 623.84s47 67 10 187-67 118-86 116" />
    <path
      className="st17"
      d="M892.19 616.84s58.5 65.5 21.5 185.5-65.5 117.5-84.5 115.5"
    />
    <path
      className="st17"
      d="M876.19 617.84s62.5 64.5 25.5 184.5-67 111-86 109"
    />
    <path
      className="st17"
      d="M859.19 613.84s66.5 68.5 29.5 188.5-67.5 109.5-86.5 107.5"
    />
    <path
      className="st17"
      d="M854.69 626.34c-4.08-4.08 54.74 60.08 19 176-37 120-67 111-86 109"
    />
    <path
      className="st17"
      d="M840.69 626.34c-4.08-4.08 54.74 60.08 19 176-37 120-67 111-86 109"
    />
    <path className="st17" d="M825.69 626.34s56 56 19 176-67 111-86 109" />
    <path className="st17" d="M812.69 626.34s56 56 19 176-67 111-86 109" />
    <path className="st17" d="M799.69 626.34s56 56 19 176-67 111-86 109" />
    <path className="st17" d="M788.69 626.34s56 56 19 176-67 111-86 109" />
    <path
      d="M888.46 619.22h0c8.57 0 20.88 2.41 30.83 13.91 10.96 12.65 27.51 46.51 15.29 131.13-8.92 61.76-26.69 109.44-54.33 145.76-6.89 9.06-17.32 14.26-28.6 14.26-6.33 0-18.29-1.73-27.31-13.35-11.62-14.96-18.65-38.95-20.33-69.36-2.91-52.84 7.49-108.18 29.29-155.82 8.94-19.54 19.33-36.6 30.05-49.34 9.82-11.67 16.85-15.92 18.86-16.64 1.26-.27 3.51-.55 6.25-.55m0-39c-5.99 0-11.24.67-15.27 1.61-45.47 10.61-115.5 127.5-108.11 261.87 2.41 43.89 13.98 72.51 28.47 91.15 14.77 19.01 36.41 28.42 58.11 28.42 22.3 0 44.65-9.95 59.64-29.64 21.31-28.01 49.24-76.15 61.89-163.81 23.37-161.84-46.16-189.6-84.73-189.6h0z"
      fill="#fff"
      stroke="#c1272d"
      strokeWidth={3}
      strokeMiterlimit={10}
    />
    <path
      className="st19"
      d="M785.29 721.05c.39 2.29-5.61 29.29-5.61 29.29l-171-32s6-21 7-23 169.61 25.71 169.61 25.71z"
    />
    <path
      className="st0"
      d="M472.62 621.13c-4.75 0-9.42-.28-13.86-.83-31.16-3.84-54.22-21.47-66.67-50.99-9.29-22.02-9.4-42.62-9.4-43.49 0-1.8 1.36-3.3 3.15-3.48l7.26-.73 5.62-40.27a3.498 3.498 0 013.94-2.99c.55.08 55.93 7.62 112.86 7.62 54.28 0 92.89-6.92 111.66-20.02a3.484 3.484 0 013.67-.2c1.17.63 1.87 1.87 1.83 3.2l-3 87a3.51 3.51 0 01-1.41 2.69c-3.42 2.55-84.73 62.49-155.65 62.49z"
    />
    <path
      className="st1"
      d="M629.19 468.84l-3 87s-82.99 61.8-153.57 61.8c-4.54 0-9.02-.26-13.43-.8-73-9-73-91-73-91l10-1 6-43s55.49 7.65 113.34 7.65c44.59 0 90.58-4.55 113.66-20.65m0-7h-.11c-1.47.02-2.84.5-3.96 1.31-18.2 12.66-56.09 19.35-109.59 19.35-56.67 0-111.83-7.51-112.38-7.59-.32-.04-.65-.07-.96-.07-3.44 0-6.44 2.53-6.92 6.03l-5.24 37.55-4.52.45a7 7 0 00-6.3 6.97c0 .9.11 22.18 9.68 44.85 12.97 30.73 36.99 49.1 69.47 53.1 4.58.56 9.39.85 14.29.85 30.41 0 66.24-10.66 106.49-31.7 29.72-15.53 50.39-30.84 51.26-31.49a7.016 7.016 0 002.82-5.37l2.99-86.81c.01-.14.01-.29.01-.44a7 7 0 00-6.96-7c-.05.01-.06.01-.07.01zm0 14z"
    />
    <path
      className="st19"
      d="M786.69 910.34L612.69 891.34 618.69 921.34 791.69 938.34z"
    />
    <path
      className="st19"
      d="M452.69 426.34L451.69 525.34 501.69 535.34 498.69 427.34z"
    />
    <path
      className="st19"
      d="M358.69 249.34s-1 54 156 52l422 72s9 5 36-3 105-39 109-49"
    />
    <ellipse className="st1" cx={509.19} cy={255.84} rx={116} ry={32} />
    <g>
      <path
        className="st0"
        d="M865.19 981.33c-.15 0-.31-.01-.47-.03l-172-23c-.35-.05-.69-.15-1.01-.3l-71-33a3.501 3.501 0 01-1.9-4.11 3.487 3.487 0 013.37-2.56c.13 0 .25.01.38.02l167 18c.41.04.82.16 1.19.35l76 38c1.53.77 2.28 2.52 1.78 4.16a3.489 3.489 0 01-3.34 2.47z"
      />
      <path
        className="st1"
        d="M622.19 921.84l167 18 76 38-172-23-71-33m-.01-7.01a7 7 0 00-6.74 5.13c-.92 3.3.69 6.78 3.79 8.22l71 33c.64.3 1.32.5 2.02.59l172 23a7.012 7.012 0 007.62-4.88c1.01-3.27-.5-6.79-3.56-8.32l-76-38a7.1 7.1 0 00-2.38-.7l-167-18c-.25-.02-.5-.04-.75-.04z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M783.19 913.33c-.11 0-.22-.01-.33-.02l-169-16a3.498 3.498 0 01-3.17-3.44l-2-173c-.01-1.03.43-2.01 1.2-2.68a3.492 3.492 0 012.82-.82l165 25c1.66.25 2.91 1.65 2.97 3.33l6 164c.04 1.01-.36 1.98-1.1 2.67-.64.63-1.51.96-2.39.96z"
      />
      <path
        className="st1"
        d="M612.19 720.84l165 25 6 164-169-16-2-173m0-7c-1.68 0-3.31.6-4.59 1.72a6.977 6.977 0 00-2.4 5.36l2 173a7.005 7.005 0 006.34 6.89l169 16a6.985 6.985 0 005.46-1.88 6.992 6.992 0 002.19-5.35l-6-164a7.009 7.009 0 00-5.95-6.67l-165-25c-.36-.05-.71-.07-1.05-.07z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M783.19 728.33c-.19 0-.38-.01-.56-.05l-166-27c-1.21-.2-2.23-1.01-2.69-2.15s-.28-2.44.46-3.41l106-140a3.52 3.52 0 012.79-1.39c.21 0 .42.02.63.06l141 26c1.12.21 2.06.94 2.54 1.97a3.5 3.5 0 01-.14 3.21l-81 141a3.47 3.47 0 01-3.03 1.76z"
      />
      <path
        className="st1"
        d="M723.19 557.84l141 26-81 141-166-27 106-140m-.01-7.01c-2.17 0-4.24 1.01-5.58 2.77l-106 140a7.018 7.018 0 00-.92 6.83 7.006 7.006 0 005.37 4.31l166 27a7.008 7.008 0 007.2-3.42l81-141a6.993 6.993 0 00.28-6.43 6.989 6.989 0 00-5.08-3.94l-141-26c-.42-.08-.84-.12-1.27-.12z"
      />
    </g>
  </svg>
);

export default SvgComponent;
