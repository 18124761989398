/* eslint-disable prettier/prettier */
import React from "react";

const SvgComponent = () => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1195.15 1195.15"
    xmlSpace="preserve"
  >
    <style>{".st0{fill:#fff}"}</style>
    <circle
      cx={597.58}
      cy={597.58}
      r={597.08}
      fill="#c1272d"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      className="st0"
      d="M284.29 1011.32c24.54-24.13 47.02-46.58 69.96-68.55 6.29-6.02 8.62-11.47 6.31-20.46-5.2-20.18-9.65-40.62-12.93-61.2-1.66-10.38-6.94-13.73-16.11-15.31-18.5-3.19-37.12-6.14-55.23-10.92-11.89-3.14-19.18.44-27.08 8.86-17.49 18.64-35.95 36.37-54.07 54.41-3.56 3.54-7.38 6.82-11.47 10.57-21.43-71.89 34.59-154.17 111.06-163.18 17.28-2.04 35.32.14 52.73 2.48 8.81 1.19 14.31-.13 20.45-6.34 44.69-45.2 89.69-90.1 134.64-135.04 79.27-79.26 158.52-158.54 237.98-237.6 6.39-6.36 9.5-11.76 7.27-21.57-22.53-99.14 58.72-183.42 160.89-164.99-2.81 3.64-4.8 6.84-7.39 9.45-20.22 20.37-40.92 40.28-60.55 61.21-4.1 4.37-6.73 12.67-5.93 18.6 2.71 20.12 7.57 39.95 10.99 59.99 1.59 9.31 5.22 14.36 15.46 15.99 19.03 3.02 38.06 6.81 56.55 12.17 12.51 3.62 20.11-.12 28.43-8.99 20.65-22.01 42.23-43.14 63.44-64.63 1.95.43 3.89.87 5.84 1.3-.91 19.13.72 38.8-3.16 57.3-15.53 73.9-89.06 120.11-162.55 102.98-10.57-2.46-17-.33-24.42 7.19-48.36 49.06-97.18 97.66-145.89 146.37-74.81 74.79-149.54 149.66-224.65 224.13-7.58 7.52-9.43 14.04-7.03 24.49 22.21 96.76-65.77 185.69-163.54 161.29z"
    />
    <path
      className="st0"
      d="M596.5 208.6c-6.45 13.27-14.47 14.48-26.93 9.61-27.79-10.86-55.45-8.03-81.39 6.67-26.99 15.29-52.53 32.21-64.99 62.87-10.87 26.74-8.05 43.51 12.62 63.63 47.1 45.85 94.31 91.58 141.6 137.24 4.43 4.28 9.71 7.68 14.24 11.21-22.18 22.03-42.13 41.84-58.27 57.87-36.73-34.56-74.33-69.62-111.54-105.08-13.58-12.95-26.2-26.9-39.33-40.33-35.74-36.57-84.63-27.22-98.22 22.47-7.89 28.84-26.2 46.07-45 64.86-15.5 15.49-33.71 15.15-49.69-.28-12.76-12.32-25.31-24.87-37.57-37.68-14.84-15.52-14.79-32.87-.15-48.55 6.05-6.48 12.82-12.3 18.68-18.93 9.94-11.25 20.95-17.99 36.89-20.2 29.74-4.11 50.93-29.54 56.09-59.7 1.46-8.52 5.65-18.38 11.87-23.98 34.03-30.63 67.04-62.99 104.22-89.36 65.43-46.41 134.28-39.52 202.25-3.08 5.37 2.84 9.91 7.22 14.62 10.74zM621.15 679.67c28.72-28.72 56.96-56.97 89.25-89.25 9.24 10.77 19.11 24.12 30.8 35.63 99.7 98.13 199.74 195.91 299.64 293.83 17.8 17.45 17.82 23.64.4 41.13-19.15 19.24-38.22 38.55-57.64 57.52-11.24 10.98-20.18 11.23-31.13.07-110.38-112.54-220.58-225.25-330.8-337.94-.68-.7-.93-1.82-.52-.99z"
    />
  </svg>
);

export default SvgComponent;
