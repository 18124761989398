/* eslint-disable prettier/prettier */
import React from 'react'

const SvgComponent = () => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1195.15 1195.15"
    xmlSpace="preserve"
  >
    <style>{'.st0{fill:#fff}'}</style>
    <circle
      cx={597.58}
      cy={597.58}
      r={597.08}
      fill="#c1272d"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      className="st0"
      d="M1004.01 600.31h-78.55c-2.23-36.15-2.98-71.52-6.83-106.55-16.6-151.31-146.53-268.59-303.01-274.56-152.21-5.8-290.93 103.73-319.51 252.28-29.21 151.83 49.53 298.76 191.26 356.88 140.49 57.62 300.7 6.16 385.63-123.85 4.36-6.67 8.87-13.23 18.95-28.23 0 49.05 1.74 88.69-1.12 128-.8 10.97-13.58 22.84-23.51 31.11-116.21 96.8-248.19 123.97-390.6 75.41-142.78-48.68-231.92-151.47-261.09-299.65-43.24-219.67 97.12-423.66 317.14-467.44 210.87-41.96 418.45 97.55 463.7 312.08 4.11 19.5 6.35 39.6 7.24 59.52 1.22 27.19.3 54.47.3 85z"
    />
    <path
      className="st0"
      d="M320.45 534.76c.04-158.02 127.3-285.78 284.89-286 157.42-.22 286 127.57 286.59 284.83.59 158.76-128.61 288.35-287.01 287.86-157.38-.48-284.51-128.6-284.47-286.69zm81.73 1.44c.86 111.64 92.43 203.24 203.33 203.39 112.02.15 205.03-93.44 204.61-205.89-.42-111.8-96.31-206.01-207.09-203.49-112.07 2.54-201.7 94.48-200.85 205.99zM922.14 677.62h79.6V859.5c-2.32 1.13-4.12 2.59-6.08 2.88-73.52 10.71-73.52 10.7-73.52-62.22V677.62zM292.55 895.64H211.1V713.85c24.07 34.66 51.86 67.83 71.26 105.35 10.61 20.5 6.99 48.34 10.19 76.44zM190.81 926.46h115.71v58.44H190.81v-58.44zM992.35 971.27c-67.49 8.16-59.28 18.06-65.66-74.36h72.77c-2.51 26.16-4.75 49.66-7.11 74.36zM925.73 634.28h75.46c.07 2.19.13 4.38.2 6.57h-75.58c-.03-2.19-.05-4.38-.08-6.57z"
    />
    <path
      className="st0"
      d="M779.38 534.09c.44 95.81-75.84 173.59-171.09 174.46-96.88.88-175.99-76.56-176.47-172.75-.47-95 77.04-173.78 171.58-174.39 97.82-.62 175.54 75.63 175.98 172.68zm-82.52 1.1c.02-51.12-41.98-93.13-92.34-92.36-48.61.74-90.51 43.21-90.64 91.88-.13 49.81 43.51 92.91 93.38 92.22 49.07-.68 89.58-42.16 89.6-91.74z"
    />
  </svg>
)

export default SvgComponent
