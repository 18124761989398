/* eslint-disable prettier/prettier */
import React from 'react'

const SvgComponent = () => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1195.15 1195.15"
    xmlSpace="preserve"
  >
    <style>{'.st0{fill:#fff}.st1{fill:#c1272d}'}</style>
    <circle
      cx={597.58}
      cy={597.58}
      r={597.08}
      fill="#c1272d"
      stroke="#000"
      strokeMiterlimit={10}
    />
    <path
      className="st0"
      d="M362.36 1008.2c-.97 0-1.95-.13-2.89-.39L202.3 964.95c-2.61-.71-4.85-2.35-6.32-4.62l-85.73-132.16c-.94-1.46-1.53-3.14-1.71-4.86l-14.29-139.3c-.36-3.56 1.03-7.08 3.73-9.42l107.16-92.87a10.986 10.986 0 0115.49 1.11c3.97 4.58 3.47 11.53-1.11 15.49l-102.84 89.13 13.43 130.99 82 126.42 153.12 41.76c5.84 1.59 9.3 7.64 7.71 13.49-1.29 4.76-5.65 8.09-10.58 8.09z"
    />
    <path
      className="st1"
      d="M212.33 582.54a7.486 7.486 0 014.91 13.14L113.02 686l13.71 133.64 83.19 128.25L364.33 990a7.48 7.48 0 015.25 9.19 7.488 7.488 0 01-7.22 5.52c-.65 0-1.31-.09-1.97-.27l-157.16-42.86a7.453 7.453 0 01-4.31-3.15l-85.73-132.16a7.57 7.57 0 01-1.17-3.31l-14.29-139.3c-.25-2.43.7-4.82 2.54-6.42l107.16-92.87a7.467 7.467 0 014.9-1.83m0-7c-3.48 0-6.85 1.26-9.48 3.54L95.69 671.95c-3.56 3.09-5.4 7.73-4.92 12.42l14.29 139.3c.23 2.27 1.01 4.48 2.25 6.4l85.73 132.16a14.47 14.47 0 008.34 6.09l157.16 42.86c1.25.34 2.53.51 3.82.51 6.51 0 12.25-4.39 13.97-10.67 2.1-7.71-2.46-15.68-10.16-17.79l-151.83-41.41-80.82-124.59-13.16-128.33 101.47-87.94c6.03-5.23 6.69-14.4 1.46-20.43a14.521 14.521 0 00-10.96-4.99z"
    />
    <path
      className="st0"
      d="M318.7 917.62c-44.37 0-75.29-32.94-89.77-52.58-13.38-18.14-32.48-51.19-42.3-84.64-12.3-41.91-7.29-74.68 14.49-94.78 13.62-12.57 30.19-18.95 49.24-18.95 37.42 0 71.27 24.62 72.69 25.67.17.12.33.25.48.39 16.03 14.58 35.55 43.88 49.73 74.67 9.81 21.3 25.57 62.04 20.91 95.63-2.81 20.3-12.49 34.7-28.76 42.81-15.7 7.82-31.41 11.78-46.71 11.78.01 0 .01 0 0 0z"
    />
    <path
      className="st1"
      d="M250.36 672.67c35.88 0 69.13 24.5 69.13 24.5 39.29 35.72 107.16 171.45 43.24 203.29-15.87 7.91-30.58 11.15-44.03 11.15-40.71 0-69.91-29.77-84.94-50.14-25.79-34.97-75.01-128.59-28.57-171.45 13.93-12.85 29.79-17.35 45.17-17.35m0-12c-20.6 0-38.54 6.91-53.31 20.54-23.57 21.75-29.16 56.64-16.17 100.88 10.03 34.16 28.9 67.09 43.23 86.51 15.16 20.55 47.6 55.02 94.6 55.02 16.23 0 32.85-4.18 49.38-12.41 17.89-8.91 28.97-25.29 32.03-47.35 4.87-35.1-11.32-77.07-21.4-98.96-14.71-31.94-34.31-61.29-51.14-76.6-.3-.28-.62-.54-.95-.78-1.51-1.1-36.91-26.85-76.27-26.85z"
    />
    <path
      className="st0"
      d="M619.53 807.83c-.72 0-1.43-.22-2.04-.65a3.49 3.49 0 01-1.39-3.53c6.85-34.27 4.61-95.41-24.27-154.95-23.73-48.93-74.31-113.74-180.44-144.61a3.49 3.49 0 01-2.52-3.31c-.03-1.84.55-2.26 3.74-4.57 1.46-1.06 3.55-2.56 6.05-4.36 5.01-3.6 11.69-8.39 18.38-13.17 27.16-19.44 27.23-19.46 27.93-19.69 1.25-.41 2.91-.61 5.08-.61 23.21 0 108.56 22.82 163.75 92.68 21.33 27.01 35.72 58.14 42.76 92.54 8.19 40.02 6.49 84.9-5.06 133.41-.22.93-.82 1.74-1.65 2.22l-48.57 28.13c-.54.32-1.15.47-1.75.47z"
    />
    <path
      className="st1"
      d="M470.04 461.88c36.89 0 257.37 65.09 198.05 314.32l-48.57 28.13c14.29-71.44-10.72-246.46-207.17-303.61-.1-.03 53.33-38.29 53.69-38.41.88-.28 2.23-.43 4-.43m0-7v8.47-8.47c-2.54 0-4.56.26-6.17.78-1.14.37-1.14.37-10.42 6.99-5.05 3.6-11.76 8.39-18.45 13.18-6.69 4.79-13.37 9.58-18.38 13.18-2.51 1.8-4.6 3.31-6.06 4.36-.74.54-1.32.96-1.72 1.25-1.06.77-3.53 2.58-3.47 6.22a7 7 0 005.04 6.6c104.9 30.52 154.84 94.49 178.27 142.78 28.5 58.76 30.74 119 23.99 152.73-.54 2.7.55 5.46 2.79 7.06a6.98 6.98 0 004.08 1.31 7 7 0 003.51-.94l48.57-28.13a6.988 6.988 0 003.3-4.44c11.66-49.01 13.37-94.41 5.08-134.93-7.15-34.94-21.77-66.57-43.44-94.01-56-70.85-142.88-93.99-166.52-93.99z"
    />
    <path
      className="st0"
      d="M665.96 825.69c-1.2 0-2.37-.62-3.02-1.73a3.505 3.505 0 011.25-4.79s.11-.07.12-.07c16.97-9.97 28.63-32.99 32.83-64.81 4.19-31.75.77-70.29-9.63-108.51-26.59-97.79-100.45-202.29-228-202.31-1.79 0-3.3-1.36-3.48-3.15-.23-2.25.56-2.72 4.92-5.32 2.11-1.26 5.21-3.1 9.15-5.42 7.88-4.65 19.13-11.28 32.65-19.22 27.78-16.32 63.95-37.53 99.25-58.17 144.95-84.8 146.36-85.09 147.2-85.26 9.62-1.98 19.67-2.98 29.85-2.98 63.34 0 127.94 37.51 168.59 97.88 24.37 36.19 39.22 79.61 42.95 125.57 4.24 52.24-5.35 107.65-28.49 164.67a3.49 3.49 0 01-1.47 1.7l-292.9 171.45c-.56.32-1.17.47-1.77.47z"
    />
    <path
      className="st1"
      d="M779.04 267.43c125.78-.02 272.54 154.8 179.81 383.31L666.08 822.12c85.54-50.26 21.2-382.12-206.57-382.16-.72 0 287.91-169.12 290.38-169.63 9.5-1.94 19.27-2.9 29.15-2.9M666.08 822.12c-.04.02-.08.05-.12.07l.12-.07m112.98-561.69h-.02c-10.42 0-20.7 1.03-30.55 3.05-1.56.32-1.98.41-48.53 27.47-32.1 18.66-71.95 41.95-99.73 58.2-35.3 20.65-71.47 41.85-99.25 58.18-13.91 8.18-24.9 14.64-32.66 19.23-3.95 2.33-7.05 4.17-9.16 5.43-1.08.64-1.9 1.14-2.46 1.48-1.26.76-4.6 2.8-4.15 7.21a6.998 6.998 0 006.97 6.29c125.57.02 198.37 103.19 224.62 199.73 10.28 37.79 13.66 75.84 9.53 107.14-4.05 30.73-15.11 52.84-31.13 62.25 0 0-.01 0-.01.01l-.1.06c-3.34 1.95-4.46 6.24-2.51 9.58a6.985 6.985 0 006.05 3.47c1.2 0 2.42-.31 3.53-.96l.11-.06.01-.01L962.39 656.8a7.014 7.014 0 002.95-3.41c23.35-57.54 33.02-113.48 28.73-166.27-1.95-24-6.82-47.26-14.49-69.15-7.25-20.7-17.03-40.25-29.05-58.1-20.53-30.49-47.58-55.84-78.22-73.32-29.91-17.09-62.16-26.12-93.25-26.12z"
    />
    <path
      className="st0"
      d="M948.9 344.16c-.64 0-1.29-.18-1.86-.53L785.18 242.38a3.474 3.474 0 01-1.64-2.98c.01-1.21.64-2.34 1.67-2.97l79.96-48.96c.56-.34 1.19-.52 1.83-.52.67 0 1.33.19 1.91.57l157.76 102.79c1 .65 1.6 1.77 1.59 2.97a3.48 3.48 0 01-1.65 2.93l-75.86 47.41c-.56.36-1.21.54-1.85.54z"
    />
    <path
      className="st1"
      d="M867 190.46l157.76 102.79-75.86 47.41-161.86-101.25L867 190.46m0-7c-1.27 0-2.53.34-3.66 1.03l-79.96 48.96a6.991 6.991 0 00-3.34 5.94 7.01 7.01 0 003.29 5.97L945.19 346.6a7.001 7.001 0 007.42.01l75.86-47.41a6.991 6.991 0 003.29-5.87 7.006 7.006 0 00-3.18-5.93L870.82 184.59a6.953 6.953 0 00-3.82-1.13z"
    />
    <g>
      <path
        className="st0"
        d="M1018.44 518c-.41 0-.81-.07-1.21-.21a3.5 3.5 0 01-2.03-1.96l-70.48-172.3a3.495 3.495 0 011.5-4.36l79.96-45.8a3.486 3.486 0 012.9-.26c.96.34 1.72 1.08 2.1 2.02l69.54 177.01c.65 1.67-.05 3.56-1.64 4.39l-79.02 41.09c-.51.25-1.07.38-1.62.38z"
      />
      <path
        className="st1"
        d="M1027.92 296.41l69.54 177.01-79.02 41.09-70.48-172.3 79.96-45.8m0-7c-1.21 0-2.41.31-3.48.93l-79.96 45.8a6.99 6.99 0 00-3 8.72l70.48 172.3a6.988 6.988 0 004.07 3.92c.78.29 1.6.43 2.41.43 1.11 0 2.22-.27 3.23-.79l79.02-41.09a7 7 0 003.29-8.77l-69.54-177.01a6.972 6.972 0 00-4.19-4.04c-.76-.27-1.55-.4-2.33-.4z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M982.85 643.05c-.75 0-1.5-.24-2.12-.71a3.508 3.508 0 01-1.28-3.65l32.43-128.2a3.51 3.51 0 011.93-2.32l82.18-37.93c.47-.22.97-.32 1.47-.32.88 0 1.75.33 2.42.97 1.04.99 1.37 2.51.83 3.85l-52.88 130.9c-.29.73-.83 1.34-1.51 1.73l-61.73 35.24c-.55.28-1.15.44-1.74.44z"
      />
      <path
        className="st1"
        d="M1097.46 473.41l-52.88 130.9-61.73 35.24 32.43-128.2 82.18-37.94m0-7c-.99 0-1.99.21-2.93.64l-82.18 37.93a7.006 7.006 0 00-3.85 4.64l-32.43 128.2a7.008 7.008 0 006.79 8.72c1.19 0 2.39-.3 3.47-.92l61.73-35.24c1.37-.78 2.43-2 3.02-3.46l52.88-130.9c1.08-2.66.42-5.71-1.66-7.69a7.11 7.11 0 00-4.84-1.92zm0 14z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M373.07 682.82c-.64 0-1.28-.17-1.84-.52l-150.02-92.87a3.52 3.52 0 01-1.66-2.92c-.02-1.19.57-2.31 1.56-2.97L403.27 462.1c.59-.39 1.26-.59 1.94-.59s1.35.2 1.94.59l150.02 100.01a3.5 3.5 0 01-.08 5.88l-182.16 114.3c-.57.35-1.22.53-1.86.53z"
      />
      <path
        className="st1"
        d="M405.22 465.01l150.02 100.01-182.16 114.3-150.02-92.87 182.16-121.44m0-7c-1.35 0-2.71.39-3.88 1.18L219.17 580.63a7.006 7.006 0 00-3.12 5.94 7 7 0 003.31 5.83l150.02 92.87c1.13.7 2.41 1.05 3.68 1.05 1.29 0 2.58-.36 3.72-1.07l182.16-114.3c2.01-1.26 3.25-3.46 3.28-5.83s-1.14-4.6-3.12-5.92l-150-100.02a7.027 7.027 0 00-3.88-1.17z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M448.08 875.7c-.39 0-.77-.06-1.15-.19a3.494 3.494 0 01-2.09-1.98l-79.64-194.7a3.493 3.493 0 011.46-4.34l190.37-112.48a3.507 3.507 0 012.92-.3c.97.33 1.74 1.08 2.12 2.03l78.58 200.02a3.51 3.51 0 01-1.53 4.33L449.8 875.24c-.53.31-1.12.46-1.72.46z"
      />
      <path
        className="st1"
        d="M558.81 565.02l78.58 200.02L448.08 872.2l-79.64-194.7 190.37-112.48m0-7c-1.24 0-2.47.33-3.56.97L364.87 671.47a7.011 7.011 0 00-2.92 8.68l79.64 194.7c.76 1.86 2.29 3.3 4.19 3.96.75.26 1.52.39 2.29.39 1.2 0 2.39-.31 3.45-.91l189.31-107.16a7.013 7.013 0 003.07-8.65l-78.58-200.02a7.006 7.006 0 00-6.51-4.44z"
      />
    </g>
    <g>
      <path
        className="st0"
        d="M380.21 990a3.495 3.495 0 01-3.09-5.14l60.72-114.3c.32-.6.81-1.1 1.42-1.43l196.45-107.16c.53-.29 1.1-.43 1.67-.43.92 0 1.84.37 2.51 1.06a3.52 3.52 0 01.51 4.21L579.68 870.4c-.3.51-.72.93-1.22 1.23L382.01 989.5c-.56.33-1.18.5-1.8.5z"
      />
      <path
        className="st1"
        d="M637.39 765.04l-60.72 103.58L380.21 986.5l60.72-114.3 196.46-107.16m-.01-7c-1.14 0-2.29.28-3.35.85L437.58 866.05c-1.2.66-2.19 1.65-2.83 2.86l-60.72 114.3a6.99 6.99 0 001.36 8.36 6.993 6.993 0 008.42.92l196.45-117.87a7.01 7.01 0 002.44-2.46l60.72-103.58a6.996 6.996 0 00-1.01-8.41 6.979 6.979 0 00-5.03-2.13zm.01 14z"
      />
    </g>
  </svg>
)

export default SvgComponent
